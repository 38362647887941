import React from 'react';
import styled from 'styled-components';
import {Container, Row, Paragraph} from '@nab/nui-react';
import NabLogo from '../../public/images/NAB_logo.svg';

function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
}

export function Footer () {
    return(
        <React.Fragment>
            <FooterContainer>
                <InnerContainer>
                    <Container fluid noSideGutters>

                    {/* logo and links */}
                    <StyledRow>
                        <a onClick={() => openInNewTab("https://www.nab.com.au/")} data-testid="logo"><LogoImage src={NabLogo}/></a>
                        <LinksContainer data-testid="links">
                            <a onClick={() => openInNewTab("https://medfin.com.au/terms/")}>© Terms</a> |
                            <a onClick={() => openInNewTab("https://medfin.com.au/privacy-policy/")}>Privacy & GDPR</a> | 
                            <a onClick={() => openInNewTab("https://medfin.com.au/online-services-terms-and-conditions/")}>Customer Portal Terms</a> 
                        </LinksContainer>
                    </StyledRow>

                    {/* Divider */}
                    <StyledRow>
                        <StyledLine/>
                    </StyledRow>

                    {/* Text */}
                    <StyledRow data-testid="Paragraph">
                        <StyledParagraph>
                            Medfin Australia Pty Ltd ABN 89 070 811 148 Australian Credit Licence 391697 (Medfin). 
                            Medfin is a wholly owned subsidiary of National Australia Bank Limited ABN 12 004 044 
                            937 AFSL and Australian Credit License 230686 (NAB) and part of the NAB Health 
                            specialist business. The obligations of Medfin do not represent deposits or other 
                            liabilities of NAB. NAB does not guarantee or otherwise support the obligations 
                            of Medfin.
                        </StyledParagraph>
                    </StyledRow>
                    </Container>
                </InnerContainer>
            </FooterContainer>
        </React.Fragment>

    )
}

export default Footer; 

const FooterContainer = styled.div`
    background-color: white;
    padding: 0px 50px 10px 50px;
    bottom: 0;
    
    @media (min-width: 840px) {
        position: relative;
    }
`

const InnerContainer = styled.div`
`

const LogoImage = styled.img`
    height: 24px;
`

const LinksContainer = styled.div`
    margin: 5px;
    
    & > a {
        margin: 0 10px;
        color: #000000;
        text-decoration: none;
        font-size: 16px;
    }
    & > a:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

const StyledLine = styled.div`
    width: 100%;
    border-top: 1px solid #A3ADB2;
`

const StyledParagraph = styled.div`
    font-size: 12px;
`

const StyledRow = styled.div`
    display: flex;
    margin: 5px 0px;
    & > a {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }
    @media only screen and (min-width: 600px) {
        flex-direction: row;
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`