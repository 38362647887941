import React, { useMemo, useEffect } from 'react';
import type { SpaProps } from "@nab/x-spa-react";
import { MiniAppInstance } from "@nab/x-spa-react";
import styled from 'styled-components';
import AppViewport from "./AppViewport/AppViewport";
import RouteError from './Error/RouteError';
import AppInitError from './Error/AppInitError';

import Footer from './Footer/Footer';

const SpaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  flex: 1;

  [aria-hiddn="true"] {
    display: none;
  }
`;

const LoadingComponent = ({ app }) => <></>;

export function GlobalSpa({ context, activeAppInstance, initError, routeError, actions, ...others }: SpaProps) {
    useEffect(() => {
        actions.authenticate();
    }, [actions])
    return (
        
        <SpaWrapper>

            {useMemo(() => (
                
                <AppViewport>
                    {initError ? (<AppInitError app={activeAppInstance && activeAppInstance.app} error={initError}/>) : ''}
                    {!initError && routeError ? (<RouteError routeError={routeError} />) : ''}
                    {!initError && !routeError && activeAppInstance ?
                        <MiniAppInstance errorSlot={AppInitError} appInstance={activeAppInstance}/> : ''}
                    
                    <Footer />
                </AppViewport>               
                
            ), [activeAppInstance, initError, routeError])}
        </SpaWrapper>
            
        
        
    );

}

export default GlobalSpa;
