import React, { useState, useContext } from 'react';
import { Modal } from '@nab/nui-react';
import { MiniAppContext } from '@nab/nab-x-react';

const ModalPopper = props => {
    // props are: id, label, type, fields
    const { renderRoot } = useContext(MiniAppContext);

    const [isOpened, setIsOpened] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const modalHasClosed = () => {
        setIsOpened(false);
        setIsClosing(false);

        if (props.closeFunc) {
            props.closeFunc();
        }
    };

    return (
        <React.Fragment>
            {props.customButton(setIsOpened)}

            {isOpened ? (
                <Modal
                    rootNode={renderRoot}
                    id={'customModal'}
                    size={'large'}
                    alignment={'left'}
                    beginClose={isClosing}
                    onClose={modalHasClosed}
                    hasClose
                    hasBackdropClose
                    hasEscapeClose
                >
                    {props.customModal(setIsClosing)}
                </Modal>
            ) : null}
        </React.Fragment>
    );
};

export default ModalPopper;
