import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@nab/nui-react';
import { SpaContextMenu } from '@nab/x-spa-react';
import type { SpaContextMenuProps } from '@nab/x-spa-react';

const SubMenuDiv = styled.div`
    width: 100%; 
    height: 45px;
    background-color: #E4E4E4;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #e6e6e6;
      }

      li {
        display: block;
        background-color: #f5f5f5;
        padding: 0;
        margin: 0;
        align-items: center;
        font-weight: bold;

        a {
          float: left;
          border-radius: 8px 8px 0px 0px;
          margin: 0px 2px;
          color: rgb(45 85 165);
          background-color: #fff;
          text-align: center;
          padding: 14px 30px;
          text-decoration: none;
          font-size: 18px;
          transition: 0.3s;
          cursor: pointer;
        }
        a:hover,
        &:active a {
          border-bottom: 6px solid #000000;
          text-decoration: underline;
        }
      }
    }
`;

export function PortalSubMenu (props: SpaContextMenuProps) {
    return (
        <SubMenuDiv>
            <SpaContextMenu {...props} />
        </SubMenuDiv>        
    );
}

export default PortalSubMenu;