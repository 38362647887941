import React from 'react';

export function AppInitError({ app, error }) {
    return (
        <div>
            <h1>Error loading {(app && app.name) || ''}</h1>
            <p>
                {error.message.split('\n').map(m => (<span>{m}<br /></span>))}
            </p>
        </div>
    );
}

export default AppInitError;
