import React, { useMemo } from 'react';
import { SpaProps, MiniAppInstance } from '@nab/x-spa-react';
import styled from 'styled-components';
import { GlobalStyle, Theme, Accordion, AccordionItem } from '@nab/nui-react';
//import MenuSidebar from './Navbar/PortalNavBar';
import { AppError, AppLoading } from './common';
import { AppViewport } from './AppViewport/AppViewport';
import LoginNavBar from './Navbar/LoginNavBar';
import { cancelModal } from './common/ModalBody';
import ModalPopper from './common/ModalPopper';
import star from '../images/star.png'

const SpaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
    flex: 1;
`;

const FormDiv = styled.div`
    background-color: white;
    position: relative;
    overflow: hidden;
    width: 100%;
    justify-content: space-evenly;
    
    @media (min-width: 840px) {
        display: flex;
        min-height: 600px;
    }

    @media (max-width: 840px) {
        min-height: 75vh;
        // border-style: solid;
        // border-color: pink;
    }
`

const SysReqDiv = styled.div`
    background-color: #2D55A5;
    border-radius: 5px; 
    color: white;
    width: 400px;
    height: 550px;
    margin: 60px 4vw 20px 20px;

    h1 {
        padding: 0 20px 0 40px;
        font-weight: 700;
        margin-top: 0;
        
    }

    p {
        font-size: 20px;
        margin: 0px 60px 20px 55px;
    }

    @media (max-width: 840px) {
        display: none;
    }

    button {
        
        box-shadow:inset 0px 1px 0px 0px #ffffff;
        background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
        border-radius: 6px;
        border: 1px solid;
        padding: 20px 15px;
    }

    button {
        margin-left: 105px;
        margin-top: 25px;
        box-shadow:inset 0px 1px 0px 0px #ffffff;
        background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
        background-color:#ffffff;
        border-radius:6px;
        border:1px solid #dcdcdc;
        display:inline-block;
        cursor:pointer;
        color:#666666;
        font-family:Arial;
        font-size:15px;
        font-weight:bold;
        padding:18px 12px;
        text-decoration:none;
        text-shadow:0px 1px 0px #ffffff;
    }

    button:hover {
        background:linear-gradient(to bottom, #f6f6f6 10%, #ffffff 100%);
        background-color:#f6f6f6;
    }

    button:active {
        position:relative;
        top:1px;
    }
    

    img {
        width: 80px;
    }
`

const SysReqAcc = styled.div`
    padding-top: 20px;

    @media (min-width: 840px) {
        display:none;
    }
`


const BorderDiv = styled.div`    
    width: 50vw;
    padding: 60px 0 0 40px;  

    @media (max-width: 840px) {
        width: 100%;
        padding-right: 60px;
    }
`
export default function LoginSpa({ context, activeAppInstance, initError, routeError, actions, appConfig, ...others }: SpaProps) {
    return (
        <>
            <GlobalStyle normalize borderBox />
            <Theme>
                <SpaWrapper>
                   
                    <LoginNavBar/>
                    {useMemo(
                        () => (
                            <AppViewport>
                                <React.Fragment>
                                    <FormDiv>
                                        <BorderDiv>
                                            <MiniAppInstance appInstance={activeAppInstance} loadingSlot={AppLoading} errorSlot={AppError} />
                                            <SysReqAcc>
                                                <Accordion multipleExpanded headingLevel={2}>
                                                    <AccordionItem label="View our System Requirements">
                                                        <div className="accordion-content">
                                                            <p>To get the most out of Customer Portal we recommend that you meet these minimum system requirements:</p>
                                                            
                                                            <h3>Operating System:</h3>
                                                            <p>Windows 10,8,7,Vista,XP (SP3 or later); Mac OS X v10.6; Customer portal does not support Linux Operating Systems and suitability cannot be guarenteed</p>
                                                            
                                                            <h3>Browser:</h3>
                                                            <ul>
                                                                <li>Google Chrome</li>
                                                                <li>Firefox™</li>
                                                                <li>Microsoft Edge</li>
                                                                <li>Safari 8 or above</li>
                                                            </ul>

                                                            <h3>Screen Resolution:</h3>
                                                            <p> 1024 x 768 or greater</p>

                                                        </div>
                                                    </AccordionItem>
                                                </Accordion>
                                            </SysReqAcc>
                                        </BorderDiv>
                                        <SysReqDiv> 
                                            <img src={ star } alt='Star' />
                                            <img src={ star } alt='Star' />
                                            <img src={ star } alt='Star' />
                                            <img src={ star } alt='Star' />
                                            <img src={ star } alt='Star' />
                                            <h1>ARE YOU GETTING THE BEST EXPERIENCE?</h1>  
                                            <p> We have recently made some changes. For the best Customer portal experience, ensure you're using a supported browser.</p>
                                            <p> Visit our System Requirements for more information</p>
                                            <ModalPopper
                                                customButton={setIsOpened => ( 
                                                    <button 
                                                        type='button' 
                                                        id='sysReqBtn' 
                                                        onClick={() => {
                                                                    setIsOpened(true);
                                                                }}> 
                                                                System Requirements </button>
                                                                                )}
                                                                                customModal={setIsClosing => {
                                                                                    return cancelModal(setIsClosing);
                                                                                }}
                                                                            />
                                        </SysReqDiv>
                                    </FormDiv>
                                </React.Fragment>
                            </AppViewport>
                        ),
                        [activeAppInstance]
                    )}
                    
                </SpaWrapper>
            </Theme>
        </>
    );
}
