import axios, { AxiosResponse } from 'axios';
import { INavManagerAppDetails } from '../types';
import { App, ContextConfig, MenuNode } from '@nab/x-spa-react';

/**
 * Retrieve SPA context from NavManager and align to ContextConfig interface
 *
 * @todo this doesn't cater for apps which aren't included in any menu (eg. anything parameterised)
 */
export default async function getContextFromNavManager(contextId: string): Promise<ContextConfig> {
    try {
        let { data }: AxiosResponse<INavManagerAppDetails[]> = await axios.get(
            'https://dev03.nabx.intapi.extnp.national.com.au/v1/nab-x-navigation/nabx'
        );

        if (data?.length) {
            // sort apps by display order
            data = data.sort((app1, app2) => app1.displayOrder - app2.displayOrder);

            return {
                id: contextId,
                apps: data.map<App>(appDetails => {
                    const { app, title, url } = appDetails;
                    return {
                        appType: 'XV1',
                        id: app.id,
                        name: title,
                        route: `/${url}`,
                        tag: app.webComponentTag,
                        assetManifestUrl: app.manifest
                    };
                }),
                menu: data.map<MenuNode>(({ url, title, icon }) => {
                    return {
                        icon: `${icon}Icon`,
                        label: title,
                        route: `/${url}`
                    };
                }),
                defaultRoute: `/${data[0].url}`
            };
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('unable to read data from nav manager', e);
    }
}
