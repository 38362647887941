import React, { useMemo, useEffect, useContext, useState } from 'react';
import { SpaProps, MiniAppInstance, useActions } from '@nab/x-spa-react';
import styled from 'styled-components';
import { GlobalStyle, Theme } from '@nab/nui-react';
import PortalNavBar from './Navbar/PortalNavBar';
import { AppError, AppLoading } from './common';
import { AppViewport } from './AppViewport/AppViewport';
import Footer from './Footer/Footer';
import PortalSubMenu from './Navbar/PortalSubMenu';
import { MiniAppContext } from '@nab/nab-x-react';


const SpaWrapper = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;

    @media (max-width: 640px) {
        min-height: 75vh;
    }

    @media (min-width: 640px) {
        min-height: 80vh;
    }

    @media (min-width: 800px) {
        min-height: 85vh;
    }

`;

const MenuAndViewport = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const MainContainer = styled.div`
    background-color: white;
    padding-top: 20px;
`;

const BorderDiv = styled.div`
    width: 100%;
`;


export default function PortalSpa({ context, activeAppInstance, initError, routeError, actions, appConfig, ...others }: SpaProps) {
    
    // const appContext = useContext(MiniAppContext);

    // const { addEventListener, removeEventListener } = appContext.actions;
    const { addEventListener, removeEventListener } = useActions();

    const [latestAccounts, setLatestAccounts] = useState('');
    const [latestFunds, setLatestFunds] = useState('');

    const eventListener = (eventData) => {
        // console.log(eventData)
        if (eventData.latestAccounts || eventData.latestAccounts == '') {
            setLatestAccounts(eventData.latestAccounts)
        }

        if (eventData.latestFunds || eventData.latestFunds == '') {
            setLatestFunds(eventData.latestFunds)  
        }
          
    }
    useEffect(() => {
        addEventListener(`latestAccounts`, eventListener);
        addEventListener(`latestFunds`, eventListener);
        return () => {
            // removeEventListener(`latestAccounts`, eventListener);
            // removeEventListener(`latestFunds`, eventListener);
        };
    })
  
    useEffect(()=>{
        if(context && context.menu) {    
            context.menu[0]['route'] = '/accounts/' + (latestAccounts ? latestAccounts: '');
            context.menu[1]['route'] = '/fundstransfer/' + (latestFunds ? latestFunds: '');
        }
    }, [latestAccounts, latestFunds])
   
    return (
        <>
            <GlobalStyle normalize borderBox />
            <Theme>
                <SpaWrapper>
                    <MenuAndViewport>
                        {useMemo(
                            () => (
                                <React.Fragment>
                                <PortalNavBar />
                                <PortalSubMenu
                                    activeRoute={activeAppInstance && activeAppInstance.app.route}
                                    items={(context && context.menu) || []}
                                    baseRoute={activeAppInstance && appConfig && appConfig.route ? appConfig.route.matchedPath : '/'}
                                    navigate={actions && actions.navigate}
                                />
                                </React.Fragment>
                            ),
                            [activeAppInstance, context?.menu]
                        )}
                        {useMemo(
                            () => (
                                <AppViewport>
                                    {/* This component is essentially the route switch and will automatically load the mini-app which corresponds with the current URL */}
                                    <React.Fragment>
                                        <MainContainer>
                                            <BorderDiv>
                                                <MiniAppInstance appInstance={activeAppInstance} loadingSlot={AppLoading} errorSlot={AppError} />
                                            </BorderDiv>
                                        </MainContainer>
                                    </React.Fragment>
                                </AppViewport>
                            ),
                            [activeAppInstance]
                        )}
                    </MenuAndViewport>
                </SpaWrapper>
            </Theme>

        </>
    );
}
