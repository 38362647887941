import React from 'react';
import { Form, FormItem, FormInput, FormDropdown, Button, H2, FormTextArea, Row, Col } from '@nab/nui-react';
import styled from 'styled-components';

export const cancelModal = (setIsClosing) => {
    return (
        <>
            <ModalContainer>
                <Heading>
                    <h1> System Requirements </h1>
                </Heading>
                <div className="accordion-content">
                    <p>To get the most out of Customer Portal we recommend that you meet these minimum system requirements:</p>
                    
                    <h3>Operating System:</h3>
                    <p>Windows 10,8,7,Vista,XP (SP3 or later); Mac OS X v10.6; Customer portal does not support Linux Operating Systems and suitability cannot be guaranteed</p>
                    
                    <h3>Browser:</h3>
                    <ul>
                        <li>Google Chrome</li>
                        <li>Firefox™</li>
                        <li>Microsoft Edge</li>
                        <li>Safari 8 or above</li>
                    </ul>

                    <h3>Screen Resolution:</h3>
                    <p> 1024 x 768 or greater</p>

                </div>
            </ModalContainer>
        </>
    );
};

const ModalContainer = styled.div``;

const Heading = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media only screen and (min-width: 600px) {
        align-items: center;
        flex-direction: row;
    }
    @media only screen and (max-width: 600px) {
        align-items: start;
        flex-direction: column;
    }
`;

const Divider = styled.div`
    float: right;
    margin-top: 15px;
`;