/* eslint-disable no-console */
import './fonts.css';
import './style.css';
import axios from 'axios';
import {
    DafInMemoryCacheStrategy,
    cacheToken,
    DafExchangeStrategy,
    start,
    registerSpaComponent,
    NullAuthStrategy,
    ContextConfig,
    AuthConfig
} from '@nab/x-spa-react';
import LoginSpa from './components/LoginSpa';
import PortalSpa from './components/PortalSpa';
import GlobalSpa from './components/GlobalSpa';
import getContextFromNavManager from './utils/getContextFromNavManager';
import { UserOrg } from '@nab/x-types';
import { Logout } from './components/helper';


/**
 * Retrieve SPA context config
 */

// Ideally return type ContextConfig but this is causing issues with the data type...
async function spaContextProvider(contextId: string): Promise<any> {
    try {
        if (contextId === 'root-config') {
            return (await axios.get(`config.json`)).data;
        } else {
            return (
                (contextId === 'root2' && (await getContextFromNavManager(contextId))) ||
                (await axios.get(`portal/config/context-${contextId}.json`)).data
            );
        }
    } catch (e) {
        throw new Error(`Failed to retrieve context ${contextId}`);
    }
}

registerSpaComponent('medfin-identity', LoginSpa );
registerSpaComponent('medfin-miniapp', PortalSpa );

window.addEventListener('load', async () => {
    try {
        const shellConfig = await spaContextProvider('root-config');

        const spaShell = await start({
            // React component which provides the main layout for the shell
            rootComponent: GlobalSpa,

            // Named set of apps and menu items which live in this shell
            rootContextId: 'medfin-global',

            // Default auth config inherited by all apps which don't explicitly override it
            authConfig: {
                context: 'external', // 'internal' for employee facing shells or 'external' for customer facing shells
                environment: 'sit1', // 'prod', 'sit1', 'dev', 'local' etc
                tokenType: 'Bearer',
                tenant: 'medfin',
                tokenSource: 'DAF'
            },

            // Auth strategies applicable to this shell (will most likely include the DafTokenExchangeStrategy)
            authStrategies: [new DafExchangeStrategy(`${shellConfig['daf']['default']['dafApiUrl']}/v1/idp/oauth/token`)],

           // authStrategies: [new NullAuthStrategy()],

            // Defines how tokens are cached & validated
            authTokenCacheStrategy: new DafInMemoryCacheStrategy(),

            // Defines how context config is retrieved (eg. from an API, JSON file on disk etc...)
            spaContextProvider,

            userConfig: {
                authenticated: false,
                id: 'userId123',
                org: 'Medfin' as UserOrg,
                type: 'banker'
            },

            // Where should the shell be rendered?
            hostElement: document.body
        });

        spaShell.config.actions.addEventListener('app_start', async () => {
            if (sessionStorage.bearerToken) {
                await cacheToken({
                    tokenValue: sessionStorage.bearerToken,
                    config: {
                        isSeed: true,
                        tokenType: 'Bearer',
                        tokenSource: 'DAF',
                        tenant: 'medfin',
                        context: 'external',
                        environment: 'sit1'
                    }
                });
            } else {
                await spaShell.config.actions.navigate('/portal/login/identity');
            }
        });
        spaShell.config.actions.addEventListener('user_authenticated', async data => {
            const config: AuthConfig = {
                isSeed: true,
                tokenType: 'Bearer',
                tokenSource: 'DAF',
                tenant: 'medfin',
                context: 'external',
                environment: 'sit1'
            };

            if (data) {
                await cacheToken({
                    tokenValue: data.bearerToken,
                    config
                });
                await spaShell.config.actions.navigate('/portal/main');
            } else if (sessionStorage.bearerToken) {
                await cacheToken({
                    tokenValue: sessionStorage.bearerToken,
                    config
                });
                await spaShell.config.actions.navigate('/portal/main');
            }
        });
        spaShell.config.actions.addEventListener('user_timeout', async () => {
            alert('Something went wrong OR Your session has expired. Please login again & try. If the issue still persists, please contact Medfin Client Services on 02 8074 3242 or 1300 728 718 during business hours, from Monday to Friday, 8:30am – 5:30pm AEST');
            window.history.replaceState({}, document.title, window.location.origin);
            sessionStorage.clear();
            window.location.reload();
        });
        // Forcefully logs out user, doing necessary acttions as defined in logout function like revoking token, etc.
        spaShell.config.actions.addEventListener('force_logout', async () => {
            Logout();
        });
        // Forcefully logs out user, doing necessary acttions as defined in logout function like revoking token, etc.
        spaShell.config.actions.addEventListener('force_logout_updated_terms', async () => {
            alert('Newest version of Terms and Conditions have not been accepted. Please log in again and accept to continue using this application.')
            Logout();
        });
        console.log('SPA started successfully');
    } catch (e) {
        console.error('SPA failed to start', e);
    }
});
