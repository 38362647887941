import { removeToken } from '@nab/x-spa-react';
import axios from 'axios';

async function Logout() {
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json'
    }
  };

  // Attempt to revoke token for security reasons, ideally works but in case it doesn't
  // Proceed with logout
  try {
      const config = await axios.get(`config.json`)
      const loginConfig = await axios.get(`portal/config/context-medfin-identity-miniapp.json`)

      // clientId of the identity miniapp - variable depending on env
      // loginConfig.data['apps'][0]['appMetaData']['properties']['appConfig']['authConfig']['clientId']

      await axios.post(
        config.data['daf']['default']['dafrevokeApiUrl'],
        { 
          "token": sessionStorage.bearerToken,
          "client_id": loginConfig.data['apps'][0]['appConfig']['clientId'],
          "token_type_hint": "access_token"
        }, 
        axiosConfig
      );

  } catch {}
  
  await removeToken(sessionStorage.bearerToken); // removes the token from cache

  sessionStorage.clear();
  window.history.replaceState({}, document.title, window.location.origin);
  window.location.reload();

}

function openInNewTab(url) {
  var win = window.open(url, '_blank');
  win.focus();
}

export {
    Logout,
    openInNewTab
}