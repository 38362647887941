import React from 'react';
import styled from 'styled-components';
import { SpaContextMenu } from '@nab/x-spa-react';
import type { SpaContextMenuProps } from '@nab/x-spa-react';
import { H2, Button, LogOutIcon, IconMenu } from '@nab/nui-react';
import { openInNewTab } from '../helper';

const medfinLogo = require('../../public/images/MEDFIN_horizontal_logo.png')

const MenuWrapper = styled.div`
  overflow: hidden;
  background-color: #2D55A5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 50px;

  & > a {
      float: left;
      color: #f2f2f2;
      text-align: center;
      padding: 14px 30px;
      text-decoration: none;
      font-size: 17px;
      transition: 0.3s;
      cursor: pointer;
  }
  & > a:hover {
      background-color: #607cb3;
      border-bottom: 6px solid #000000;
  }

  ul {
    color: #f2f2f2;
    margin: 0;
    margin-right: auto;
    padding: 0;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    align-items: left;
      li {
        display: block;
        padding: 0;
        margin: 0;4
        align-items: center;

        a {
          float: left;
          color: #f2f2f2;
          text-align: center;
          padding: 14px 30px;
          text-decoration: none;
          font-size: 17px;
          transition: 0.3s;
          cursor: pointer;
        }
        a:hover,
        &:active a {
          background-color: #607cb3;
          border-bottom: 6px solid #000000;
        }
      }
    }
  }

`;


const LogoContainer = styled.img`
  width: 156px;
  margin-right: 20px;
`
const Padding = styled.div`
    margin: 0 15px;
    display: flex;
    align-items: center;
`

const RightLinks = styled.span`
  margin-right: 3px;
  a {
    text-decoration: underline;
  }
  a:hover {
    cursor: pointer;
  }
`

const StyledContainer = styled.div`
  @media (max-width: 450px) {
    display: none;
  }
  color: white;
  a {
    margin: 0 10px;
  }
`

const HideElement = styled.div`
  @media (max-width: 650px) {
        display: none;
  }
`

const MobileMenu = styled.div`
  @media (min-width: 470px) {
      display: none;
  }
  button {
    box-shadow: none !IMPORTANT;
  }
  ul {
    display: block !IMPORTANT;
  }
  svg {
    color: white !IMPORTANT;
    fill: none !IMPORTANT;
  }
  svg:hover {
    color: white;
  }
  svg:focus {
    color: white
  }
`

export function LoginNavbar (props) {
    return (
        <MenuWrapper>
            
            <Padding>
              <LogoContainer data-testid="Logo" src={medfinLogo}/>
              <HideElement>
                <H2 isOnDarkBg variant="impact">Customer Login</H2>
              </HideElement>
            </Padding>
            
            <StyledContainer>
              <RightLinks>
                  <a onClick={() => openInNewTab("https://medfin.com.au/contact/")}>Contact Us</a>
                </RightLinks>
            </StyledContainer>

            <MobileMenu>
                <IconMenu
                  id="mobileMenu"
                  label="Contact Us"
                  color="white"
                  items={[
                    { label: 'Contact Us', onClick: () => openInNewTab("https://medfin.com.au/contact/")}
                  ]}
                />
            </MobileMenu>
        </MenuWrapper>
    );
}

export default LoginNavbar;