import React from 'react';
import { Spinner } from '@nab/nui-react';
import { LoadingSlotProps } from '@nab/x-spa-react/module/components/MiniAppInstance';
import styled from 'styled-components';

const MedfinSpinner = styled(Spinner)`
    & > div > div {
        border-bottom: 0.3125rem solid #2d55a5;
        border-left: 0.3125rem solid #2d55a5;
    }
`;

const AppLoading: React.FC<LoadingSlotProps> = ({ app }) => {
  return (
    <div
        className='loadingScreen'
        style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}
        >
        <MedfinSpinner data-testid="Spinner" size="lg" />
    </div>  
  );
};

export default AppLoading;
