import React from 'react';
import styled from 'styled-components';
import { H2, Button, LogOutIcon, Link, IconMenu } from '@nab/nui-react';
import  { Logout, openInNewTab } from '../helper';

const medfinLogo = require('../../public/images/MEDFIN_horizontal_logo.png')

const MenuWrapper = styled.div`
  overflow: hidden;
  background-color: #2D55A5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 50px;
  & > a {
      float: left;
      color: #f2f2f2;
      text-align: center;
      padding: 14px 30px;
      text-decoration: none;
      font-size: 17px;
      transition: 0.3s;
      cursor: pointer;
  }
  & > a:hover {
      background-color: #607cb3;
      border-bottom: 6px solid #000000;
  }
  ul {
    color: #f2f2f2;
    margin: 0;
    margin-right: auto;
    padding: 0;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    align-items: left;
      li {
        display: block;
        padding: 0;
        margin: 0;4
        align-items: center;
        a {
          float: left;
          color: #f2f2f2;
          text-align: center;
          padding: 14px 30px;
          text-decoration: none;
          font-size: 17px;
          transition: 0.3s;
          cursor: pointer;
        }
        a:hover,
        &:active a {
          background-color: #607cb3;
          border-bottom: 6px solid #000000;
        }
      }
    }
  }
`;

const MedfinButton = styled.div`
    float: right;
    margin: 0 3px;
    align-items: center;
    color: white;
    & > path {
      color: green !IMPORTANT;
      fill: green !IMPORTANT;
    }
    a {
      color: white;
      margin: 0 10px;
    }
`

const AlignRight = styled.div`
  justify-content: space-between;
  display: block;
  float: right;
`

const LogoContainer = styled.img`
  width: 156px;
`
const Padding = styled.div`
    float: right;
    margin-top: 15px;
`

const RightLinks = styled.span`
  margin-right: 3px;
  a {
    text-decoration: underline;
  }
  a:hover {
    cursor: pointer;
  }
`

const HideElement = styled.div`
  @media (max-width: 530px) {
        display: none;
  }
`


const PCMenu = styled.div`
  @media (max-width: 756px) {
      display: none;
  }
  & > button {
    color: #2D55A5 !IMPORTANT;
    text-color: #2D55A5 !IMPORTANT;
    background-color: #FFFFFF !IMPORTANT;
    border: none !IMPORTANT;
  }
  & > button:hover {
    color: #FFFFFF !IMPORTANT;
    background-color: #2D55A5 !IMPORTANT;
    border: 1px solid #FFFFFF !IMPORTANT;
    transition: 0.3s;
  }
  & > button svg {
    color: #2D55A5 !IMPORTANT;
  }
  & > button:hover svg {
    color: #FFFFFF !IMPORTANT;
  }
  & > button:active {
    box-shadow: 0 0 0 3px #4f7cd6 !important;   
  }    
  & > button:hover {
      background: #000000;    
  }    
  & > button:focus {
      background: #2d55a5;
      box-shadow: 0 0 0 3px #4f7cd6 !important;    
  }
`

const MobileMenu = styled.div`
  @media (min-width: 756px) {
      display: none;
  }
  button {
    box-shadow: none !IMPORTANT;
  }
  ul {
    display: block !IMPORTANT;
  }
  svg {
    color: white !IMPORTANT;
    fill: none !IMPORTANT;
  }
  svg:hover {
    color: white;
  }
  svg:focus {
    color: white
  }
`



// Commented out the Self-Help Guide links, readd once we had the endpoint we want

export function PortalNavbar () {
    return (
        <MenuWrapper>
          <div>
            <LogoContainer src={medfinLogo}/>
            <Padding>
              <HideElement>
              <H2 isOnDarkBg variant="impact">CUSTOMER PORTAL</H2>
              </HideElement>
            </Padding>
            </div>

            <AlignRight>
            <MedfinButton>
              <PCMenu>
              <RightLinks>
                <a onClick={() => openInNewTab("https://www.medfin.com.au/online-services-faqs/")}>FAQs</a>
                <a onClick={() => openInNewTab("https://medfin.com.au/contact/")}>Contact Us</a>
              </RightLinks>
                <Button variant="secondary" label="Logout" icon={LogOutIcon} onClick={Logout}/>
              </PCMenu>

              <MobileMenu>
                <IconMenu
                  id="mobileMenu"
                  label=""
                  color="white"
                  items={[
                    { label: 'FAQs', onClick: () => openInNewTab("https://www.medfin.com.au/online-services-faqs/")},
                    { label: 'Contact Us', onClick: () => openInNewTab("https://medfin.com.au/contact/")},
                    { label: 'Log out', onClick: () => Logout() }
                  ]}
                />
              </MobileMenu>
            </MedfinButton> 

            </AlignRight>
        </MenuWrapper>
    );
}

export default PortalNavbar;
